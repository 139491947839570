// global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const regNum = /[^a-zA-Z0-9 ]/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[0-9]+$/;
var numFeild = /^[+-]?\d+$/;
// var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/;
var passwordRegex =
  /^(?=.*[!@#$%^&*()_+\-=\[\]{}|;:,.<>?])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[^\s]{8,32}$/;

const urlRegex = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
const regex =
  /^([0][1-9]|[1][0-9]|[2][0-9]|[3][0-7])[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}Z[A-Z\d]{1}$/;

// const { dictionary } = useContext(LanguageContext);
// console.log(messages);

let invalidText = "Character limit exceeded. Limit: 50 characters";
let feildCannot = "Field cannot be left blank";
const checkEmail = (value) => {
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return true;
  } else if (
    value.includes('"') ||
    value.includes("'") ||
    value.includes(",") ||
    value.includes(" ")
  ) {
    return true;
  } else {
    return false;
  }
};

export const loginValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "This is an error message.";
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  if (!values.password) {
    errors.password = "This is an error message.";
  }
  return errors;
};
export const forgotValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  return errors;
};
export const resetValidator = (values, lang) => {
  let errors = {};
  if (!values.password) {
    errors.password = "Enter Password";
  } else if (values.password.length < 8) {
    errors.password = "Invalid Password";
  } else if (!passwordRegex.test(values.password)) {
    errors.password = "Invalid Password";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Enter Confirm Password";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password does not match";
  }
  return errors;
};
export const signUpValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  if (!values.password) {
    errors.password = "Enter Password";
  } else if (values.password.length < 8) {
    errors.password = "Password Should be 8 characters";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Enter Confirm Password";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password doesnot match with password";
  }
  return errors;
};
export const otpValidator = (values) => {
  let errors = {};
  if (!values.otp) {
    errors.otp = "Enter OTP";
  } else if (values.otp.length < 4) {
    errors.otp = "Invalid OTP";
  }
  return errors;
};
export const getOtpValidator = (values) => {
  let errors = {};
  if (!values.mobile) {
    errors.mobile = "Enter Mobile Number";
  }
  return errors;
};
export const groupValidator = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = "Enter Title";
  }
  return errors;
};
export const addUserValidator = (values, lang) => {
  let errors = {};
  console.log(values, "Error");
  if (!values.username) {
    errors.username = "Enter Username";
  }
  if (!values.firstName) {
    errors.firstName = "Enter First Name";
  }
  if (!values.lastName) {
    errors.lastName = "Enter Last Name";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Enter Mobile Number";
  }
  if (values.phoneNumber?.length < 10) {
    errors.phoneNumber = "Invalid Mobile Number";
  }
  if (!values.password) {
    errors.password = "Enter Password";
  } else if (values.password.length < 8) {
    errors.password = "Invalid Password";
  } else if (!passwordRegex.test(values.password)) {
    errors.password = "Invalid Password";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Enter Confirm Password";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password does not match";
  }
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  return errors;
};
export const categoryValidator = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  // if (!values.description) {
  //   errors.description = "Enter Description";
  // }
  if (values.description?.length > 2000) {
    errors.description = "Character limit exceeded. Limit: 2000 characters";
  }
  return errors;
};
export const reminderValidator = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.schedule_type) {
    errors.schedule_type = feildCannot;
  }
  if (!values.send_reminder_as) {
    errors.send_reminder_as = feildCannot;
  }
  if (values.send_reminder_as === "email") {
    // if (values.groupId?.length === 0) {
    //   errors.groupId = feildCannot;
    // }
    if (!values.subject) {
      errors.subject = feildCannot;
    }
  }
  if (values.send_reminder_as === "notification") {
    if (!values.notificationTitle) {
      errors.notificationTitle = feildCannot;
    }
    if (!values.notificationType) {
      errors.notificationType = feildCannot;
    }
  }
  if (!values.body) {
    errors.body = feildCannot;
  }
  console.log(errors, "errors errors");
  return errors;
};
export const reportSheduleValidator = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.clientIds) {
    errors.clientIds = feildCannot;
  }
  if (!values.categories) {
    errors.categories = feildCannot;
  }
  if (!values.courseIds) {
    errors.courseIds = feildCannot;
  }
  if (!values.startDate) {
    errors.startDate = feildCannot;
  }
  if (!values.endDate) {
    errors.endDate = feildCannot;
  }
  if (!values.status) {
    errors.status = feildCannot;
  }
  if (!values.schedule_type) {
    errors.schedule_type = feildCannot;
  }
  if (!values.body) {
    errors.body = feildCannot;
  }
  if (!values.subject) {
    errors.subject = feildCannot;
  }
  console.log(errors, "errors errors");
  return errors;
};
export const sheduleValidator = (values) => {
  let errors = {};
  if (!values.start_date) {
    errors.start_date = feildCannot;
  }
  if (!values.count) {
    errors.count = feildCannot;
  }
  if (!values.schedule_type) {
    errors.schedule_type = feildCannot;
  }
  if (values.schedule_type === "weekly") {
    if (!values.week_day) {
      errors.week_day = feildCannot;
    }
  }
  if (values.schedule_type === "monthly") {
    if (!values.monthly_type) {
      errors.monthly_type = "Select Monthly Type";
    } else if (values.monthly_type === "onDay") {
      if (!values.onDay) {
        errors.onDay = feildCannot;
      }
      if (values.onDay > 31) {
        errors.onDay = "Invalid On Day";
      }
    } else if (values.monthly_type === "custom") {
      if (!values.week_day) {
        errors.week_day = feildCannot;
      }
      if (!values.week_type) {
        errors.week_type = feildCannot;
      }
    }
  }
  if (values.schedule_type === "yearly") {
    if (!values.year_type) {
      errors.year_type = "Select Yearly Type";
    } else if (values.year_type === "custom") {
      if (!values.onDay) {
        errors.onDay = feildCannot;
      }
      if (!values.month) {
        errors.month = feildCannot;
      }
    } else if (values.year_type === "schedule") {
      if (!values.week_day) {
        errors.week_day = feildCannot;
      }
      if (!values.week_type) {
        errors.week_type = feildCannot;
      }
      if (!values.month_two) {
        errors.month_two = feildCannot;
      }
    }
  }
  if (!values.end_date) {
    errors.end_date = feildCannot;
  }
  return errors;
};
export const bannerValidator = (values) => {
  let errors = {};
  if (!values.bannerName) {
    errors.bannerName = feildCannot;
  }
  if (values.bannerName?.length > 50) {
    errors.bannerName = invalidText;
  }
  if (!values.bannerLink) {
    errors.bannerLink = feildCannot;
  } else if (!urlRegex.test(values.bannerLink)) {
    errors.bannerLink = "Invalid Banner Link";
  }
  if (!values.startDate) {
    errors.startDate = feildCannot;
  }
  if (!values.endDate) {
    errors.endDate = feildCannot;
  }
  if (!values.startTime) {
    errors.startTime = feildCannot;
  }
  if (!values.endTime) {
    errors.endTime = feildCannot;
  }
  if (values.showTextStatus) {
    if (!values.showText) {
      errors.showText = feildCannot;
    }
  }
  return errors;
};
export const langValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (values.applicationLevel) {
    if (!values.fileUrl) {
      errors.fileUrl = feildCannot;
    }
  }
  // if (!values.description) {
  //   errors.description = feildCannot;
  // }
  if (values.description?.length > 250) {
    errors.description = "Character limit exceeded. Limit: 250 characters";
  }
  return errors;
};
export const courseReportsValidator = (values) => {
  console.log(values, "courseReportsValidator");
  let errors = {};
  if (values.clientIds?.length === 0) {
    errors.clientIds = feildCannot;
  }
  if (values.categories?.length === 0) {
    errors.categories = feildCannot;
  }
  if (values.courseIds?.length === 0) {
    errors.courseIds = feildCannot;
  }
  if (values.city?.length === 0) {
    errors.city = feildCannot;
  }
  if (!values.status) {
    errors.status = feildCannot;
  }
  if (!values.startDate) {
    errors.startDate = feildCannot;
  }
  if (!values.endDate) {
    errors.endDate = feildCannot;
  }
  return errors;
};
export const badgeValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.badgeType) {
    errors.badgeType = feildCannot;
  }
  if (values.badgeType === 2) {
    if (!values.level) {
      errors.level = feildCannot;
    }
  }
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (!values.description) {
    errors.description = feildCannot;
  }
  if (!values.logoImage) {
    errors.logoImage = feildCannot;
  }
  return errors;
};
export const musicValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.url) {
    errors.url = feildCannot;
  }

  return errors;
};
export const roleValidator = (values) => {
  console.log(values, "values values");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (values.description?.length > 500) {
    errors.description = "Character limit exceeded. Limit: 500 characters";
  }
  // if (values.permissionsIds?.length === 0) {
  //   errors.permissionsIds = feildCannot;
  // }
  console.log(errors, " role valid");
  return errors;
};
export const configValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (values.learnerCertificateToggle) {
    if (!values.learnerCertificateId) {
      errors.learnerCertificateId = feildCannot;
    }
  }
  if (values.languageToggle) {
    if (!values.languageId) {
      errors.languageId = feildCannot;
    }
  }
  // if (values.quizRetakes) {
  //   if (!values.retakes) {
  //     errors.retakes = feildCannot;
  //   }
  // }
  if (values.rainLogoToggle) {
    if (!values.rainmakerLogoUrl) {
      errors.rainmakerLogoUrl = feildCannot;
    }
  }
  if (values.rainSignatoryToggle) {
    if (!values.rainmakerSignatoryUrl) {
      errors.rainmakerSignatoryUrl = feildCannot;
    }
  }
  console.log(errors, " role valid");
  return errors;
};
export const userValidator = (values) => {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = feildCannot;
  }
  if (values.firstName?.length > 50) {
    errors.firstName = invalidText;
  }
  if (!values.lastName) {
    errors.lastName = feildCannot;
  }
  if (values.lastName?.length > 50) {
    errors.lastName = invalidText;
  }
  if (!values.email) {
    errors.email = feildCannot;
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  if (values.email?.length > 50) {
    errors.email = invalidText;
  }
  // if (!values.phoneNumber) {
  //   errors.phoneNumber = feildCannot;
  // }
  // if (values.phoneNumber?.length < 10) {
  //   errors.phoneNumber = "Invalid Mobile Number";
  // }
  if (!values._id) {
    if (!values.password) {
      errors.password = feildCannot;
    } else if (values.password.length < 8) {
      errors.password =
        "Password must contain 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    } else if (!passwordRegex.test(values.password)) {
      errors.password = "Invalid Password";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = feildCannot;
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password does not match";
    }
  }
  // if (!values.department) {
  //   errors.department = feildCannot;
  // }
  // if (!values.designation) {
  //   errors.designation = feildCannot;
  // }
  if (!values.rmUserType) {
    errors.rmUserType = feildCannot;
  }
  if (values.groupIds?.length === 0) {
    errors.groupIds = feildCannot;
  }
  console.log(errors, " errors errorserrorserrors");
  return errors;
};
export const courseValidator = (values) => {
  let errors = {};
  if (!values.categories) {
    errors.categories = feildCannot;
  }
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (!values.url) {
    errors.url = feildCannot;
  }
  return errors;
};
export const courseValidatore = (values) => {
  let errors = {};
  if (!values.categories) {
    errors.categories = feildCannot;
  }
  if (!values.courseTitle) {
    errors.courseTitle = feildCannot;
  }
  if (!values.certificateText) {
    errors.certificateText = feildCannot;
  }
  if (values.certificateText?.length > 200) {
    errors.certificateText = "Character limit exceeded. Limit: 200 characters";
  }
  if (values.language?.length === 0) {
    errors.language = feildCannot;
  }
  return errors;
};
export const quizzValidator = (values) => {
  // console.log(values, "error");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.categorieId) {
    errors.categorieId = feildCannot;
  }
  if (!values.courseId) {
    errors.courseId = feildCannot;
  }
  if (!values.languageId) {
    errors.languageId = feildCannot;
  }
  if (!values.episodeId) {
    errors.episodeId = feildCannot;
  }
  if (!values.passingPercentage) {
    errors.passingPercentage = feildCannot;
  }
  if (values.passingPercentage <= 0 || values.passingPercentage >= 100) {
    errors.passingPercentage = "Enter number between 1 to 99";
  }
  return errors;
};
export const quizzFreeValidator = (values) => {
  // console.log(values, "error");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.categorieId) {
    errors.categorieId = feildCannot;
  }
  if (!values.courseId) {
    errors.courseId = feildCannot;
  }
  if (!values.languageId) {
    errors.languageId = feildCannot;
  }
  if (!values.passingPercentage) {
    errors.passingPercentage = feildCannot;
  }
  if (values.passingPercentage <= 0 || values.passingPercentage >= 100) {
    errors.passingPercentage = "Enter number between 1 to 99";
  }
  return errors;
};
export const interQuizzValidator = (values) => {
  console.log(values, "error");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.categorieId) {
    errors.categorieId = feildCannot;
  }
  if (!values.courseId) {
    errors.courseId = feildCannot;
  }
  if (!values.languageId) {
    errors.languageId = feildCannot;
  }
  if (!values.episodeId) {
    errors.episodeId = feildCannot;
  }
  console.log(errors, "error");
  return errors;
};
export const courseLangValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  values.formValue.forEach((course, courseIndex) => {
    let courseErrors = {};
    if (!course.translateCategory) {
      courseErrors.translateCategory = feildCannot;
    }
    if (values.translateCategory?.length > 50) {
      courseErrors.translateCategory = invalidText;
    }
    if (!course.translateCourseName) {
      courseErrors.translateCourseName = feildCannot;
    }
    if (values.translateCourseName?.length > 50) {
      courseErrors.translateCourseName = invalidText;
    }
    if (!course.baiseDescription) {
      courseErrors.baiseDescription = feildCannot;
    }
    if (course.baiseDescription?.length > 2000) {
      courseErrors.baiseDescription =
        "Character limit exceeded. Limit: 2000 characters";
    }
    if (course.trailers?.length === 0) {
      courseErrors.trailers = feildCannot;
    }
    if (!course.thumbnailImage) {
      courseErrors.thumbnailImage = feildCannot;
    }
    if (!course.logoImage) {
      courseErrors.logoImage = feildCannot;
    }
    if (course.courseEpisodes?.length === 0) {
      courseErrors.courseEpisodes = feildCannot;
    }
    if (!course.feedbackFormId) {
      courseErrors.feedbackFormId = feildCannot;
    }
    if (course.isBasic) {
      // Validate episode name
      if (!course.translateCategory) {
        courseErrors.translateCategory = feildCannot;
      }
      if (values.translateCategory?.length > 50) {
        courseErrors.translateCategory = invalidText;
      }
      if (!course.translateCourseName) {
        courseErrors.translateCourseName = feildCannot;
      }
      if (values.translateCourseName?.length > 50) {
        courseErrors.translateCourseName = invalidText;
      }
      if (!course.baiseDescription) {
        courseErrors.baiseDescription = feildCannot;
      }
      if (course.baiseDescription?.length > 2000) {
        courseErrors.baiseDescription =
          "Character limit exceeded. Limit: 2000 characters";
      }
      if (course.trailers?.length === 0) {
        courseErrors.trailers = feildCannot;
      }
      if (!course.thumbnailImage) {
        courseErrors.thumbnailImage = feildCannot;
      }
      if (!course.logoImage) {
        courseErrors.logoImage = feildCannot;
      }

      // If there are any errors for the course, add them to the errors object.
      if (Object.keys(courseErrors).length > 0) {
        if (!errors.formValue) {
          errors.formValue = []; // Initialize formValue array in errors object if not already initialized
        }
        errors.formValue[courseIndex] = courseErrors;
      }
    }
    if (course.isCourse && !course.isAddButton) {
      // Validate episode name
      if (!course.courseEpisodeeName) {
        courseErrors.courseEpisodeeName = feildCannot;
      }
      if (course.courseEpisodeeName?.length > 50) {
        courseErrors.courseEpisodeeName = invalidText;
      }
      if (course.courseTags?.length === 0) {
        courseErrors.courseTags = feildCannot;
      }
      if (!course.courseDescription) {
        courseErrors.courseDescription = feildCannot;
      }
      if (course.courseDescription.length > 2000) {
        courseErrors.courseDescription =
          "Character limit exceeded. Limit: 2000 characters";
      }
      if (course.courseEpisodes?.length === 0) {
        courseErrors.courseEpisodes = feildCannot;
      }
      if (!course.url) {
        courseErrors.courseEpisodes = feildCannot;
      }

      // If there are any errors for the course, add them to the errors object.
      if (Object.keys(courseErrors)?.length > 0) {
        if (!errors.formValue) {
          errors.formValue = []; // Initialize formValue array in errors object if not already initialized
        }
        errors.formValue[courseIndex] = courseErrors;
      }
    }
    if (course.isFeedback) {
      // Validate episode name
      if (!course.feedbackFormId) {
        courseErrors.feedbackFormId = feildCannot;
      }

      // If there are any errors for the course, add them to the errors object.
      if (Object.keys(courseErrors).length > 0) {
        if (!errors.formValue) {
          errors.formValue = []; // Initialize formValue array in errors object if not already initialized
        }
        errors.formValue[courseIndex] = courseErrors;
      }
    }
  });

  console.log(errors, "errors");
  return errors;
};
export const quizzLangValidator = (values) => {
  console.log(values, "quizzLangValidator");
  let errors = {};

  values.formValue.forEach((course, courseIndex) => {
    let courseErrors = {};
    if (course.isAdd) {
      course?.questions?.forEach((question, questionIndex) => {
        let questionErrors = {};

        // Validate questionTitle
        if (!question.questionTitle) {
          questionErrors.questionTitle = `Field cannot be left blank`;
        }
        // Validate inner items (questionValues)
        question?.questionValues?.forEach((value, valueIndex) => {
          if (!value.title) {
            if (!questionErrors.questionValues) {
              questionErrors.questionValues = [];
            }
            questionErrors.questionValues[valueIndex] = {
              title: `Field cannot be left blank`,
            };
          }
        });

        if (Object.keys(questionErrors).length > 0) {
          if (!courseErrors.questions) {
            courseErrors.questions = [];
          }
          courseErrors.questions[questionIndex] = questionErrors;
        }
      });
    }
    if (course.upload_questions) {
      course?.questions?.forEach((question, questionIndex) => {
        let questionErrors = {};

        // Validate questionTitle
        if (!question.questionTitle) {
          questionErrors.questionTitle = `Field cannot be left blank`;
        }

        // Validate inner items (questionValues)
        question?.questionValues?.forEach((value, valueIndex) => {
          if (!value.title) {
            if (!questionErrors.questionValues) {
              questionErrors.questionValues = [];
            }
            questionErrors.questionValues[valueIndex] = {
              title: `Field cannot be left blank`,
            };
          }
        });

        if (Object.keys(questionErrors).length > 0) {
          if (!courseErrors.questions) {
            courseErrors.questions = [];
          }
          courseErrors.questions[questionIndex] = questionErrors;
        }
      });
    }
    if (Object.keys(courseErrors).length > 0) {
      if (!errors.formValue) {
        errors.formValue = [];
      }
      errors.formValue[courseIndex] = courseErrors;
    }
  });
  console.log(errors, "errors");

  return errors;
};
export const clientQuizzLangValidator = (values) => {
  let errors = {};

  if (values.questions?.length > 0) {
    let questionsErrors = []; // Use an array to store errors for each question

    values.questions.forEach((question, questionIndex) => {
      let questionErrors = {};

      // Validate questionTitle
      if (!question.question) {
        questionErrors.question = feildCannot;
      }

      // Validate inner items (answerOptions)
      if (question.answerOptions && question.answerOptions?.length > 0) {
        let answerOptionsErrors = [];
        question?.answerOptions?.forEach((value, valueIndex) => {
          let answerError = {};
          if (!value.option) {
            answerError.option = feildCannot;
            answerOptionsErrors[valueIndex] = answerError;
          }
        });

        if (answerOptionsErrors.length > 0) {
          questionErrors.answerOptions = answerOptionsErrors;
        }
      }

      // If there are any errors for this question, add to questionsErrors
      if (Object.keys(questionErrors)?.length > 0) {
        questionsErrors[questionIndex] = questionErrors;
      }
    });

    // If there are any questions errors, assign it to the errors object
    if (questionsErrors?.length > 0) {
      errors.questions = questionsErrors;
    }
  }

  console.log(errors); // Log to check what errors are being recorded
  return errors;
};
// export const clientQuizzInterLangValidator = (values) => {
//   let errors = {};

//   if (values.questions?.length > 0) {
//     let questionsErrors = []; // Use an array to store errors for each question

//     values.questions.forEach((question, questionIndex) => {
//       let questionErrors = {};

//       const minTimeNum = Number(question.minTime);
//       const secTimeNum = Number(question.secTime);
//       // Validate questionTitle
//       if (!question.question) {
//         questionErrors.question = feildCannot;
//       }
//       if (!question.question) {
//         questionErrors.question = feildCannot;
//       }
//       if (minTimeNum !== 0) {
//         const minutesNum = Number(question.minutes);
//         if (
//           isNaN(minutesNum) ||
//           minutesNum < 0 ||
//           minutesNum > 59 ||
//           !Number.isInteger(minutesNum)
//         ) {
//           questionErrors.minutes = "Invalid";
//         } else if (minutesNum > minTimeNum) {
//           questionErrors.minutes = `The entered timestamp exceeds the video's duration. Please enter a valid timestamp`;
//         }
//         // }
//       }

//       // Validate seconds
//       if (!question.seconds) {
//         questionErrors.seconds = "Required";
//       } else {
//         const secondsNum = Number(question.seconds);
//         if (
//           isNaN(secondsNum) ||
//           secondsNum < 0 ||
//           secondsNum > 59 ||
//           !Number.isInteger(secondsNum)
//         ) {
//           questionErrors.seconds = "Invalid";
//         } else {
//           // Only compare secondsNum to secTimeNum if minTimeNum is "00" or seconds validation is independent of minTimeNum
//           if (secTimeNum !== 0 && minTimeNum === 0) {
//             if (secondsNum > secTimeNum) {
//               questionErrors.seconds = `The entered timestamp exceeds the video's duration. Please enter a valid timestamp`;
//             }
//           }
//           // If you have additional conditions or logic for when secondsNum should not be compared to secTimeNum,
//           // include them in the above conditional statement.
//         }
//       }
//       if (questionIndex > 0) {
//         // Ensure there is a previous question to compare with
//         const prevQuestion = values.questions[questionIndex - 1];
//         const prevMinutes = Number(prevQuestion.minutes);
//         const prevSeconds = Number(prevQuestion.seconds);
//         const currentMinutes = Number(question.minutes);
//         const currentSeconds = Number(question.seconds);

//         // Comparing current question's time with the previous question's time
//         if (
//           currentMinutes === prevMinutes &&
//           currentSeconds === prevSeconds
//         ) {
//           questionErrors.minutes =
//             "Current question's time cannot be the same as the previous question's time.";
//         }
//       }

//       // Validate inner items (answerOptions)
//       if (question.answerOptions && question.answerOptions?.length > 0) {
//         let answerOptionsErrors = [];
//         question?.answerOptions?.forEach((value, valueIndex) => {
//           let answerError = {};
//           if (!value.option) {
//             answerError.option = feildCannot;
//             answerOptionsErrors[valueIndex] = answerError;
//           }
//         });

//         if (answerOptionsErrors.length > 0) {
//           questionErrors.answerOptions = answerOptionsErrors;
//         }
//       }

//       // If there are any errors for this question, add to questionsErrors
//       if (Object.keys(questionErrors)?.length > 0) {
//         questionsErrors[questionIndex] = questionErrors;
//       }
//     });

//     // If there are any questions errors, assign it to the errors object
//     if (questionsErrors?.length > 0) {
//       errors.questions = questionsErrors;
//     }
//   }

//   console.log(errors); // Log to check what errors are being recorded
//   return errors;
// };

export const interactiveQuizzLangValidator = (values) => {
  console.log(values, "quizzLangValidator");
  let errors = {};

  values.formValue.forEach((course, courseIndex) => {
    let courseErrors = {};
    const minTimeNum = Number(course.minTime);
    const secTimeNum = Number(course.secTime);
    if (course.isAdd) {
      course?.questions?.forEach((question, questionIndex) => {
        let questionErrors = {};

        // Validate questionTitle
        if (!question.questionTitle) {
          questionErrors.questionTitle = `Field cannot be left blank`;
        }
        if (minTimeNum !== 0) {
          // Proceed with validation if minTime is not "00"
          // if (!question.minutes) {
          //   questionErrors.minutes = "Required";
          // } else {
          const minutesNum = Number(question.minutes);
          if (
            isNaN(minutesNum) ||
            minutesNum < 0 ||
            minutesNum > 59 ||
            !Number.isInteger(minutesNum)
          ) {
            questionErrors.minutes = "Invalid";
          } else if (minutesNum > minTimeNum) {
            questionErrors.minutes = `The entered timestamp exceeds the video's duration. Please enter a valid timestamp`;
          }
          // }
        }

        // Validate seconds
        if (!question.seconds) {
          questionErrors.seconds = "Required";
        } else {
          const secondsNum = Number(question.seconds);
          if (
            isNaN(secondsNum) ||
            secondsNum < 0 ||
            secondsNum > 59 ||
            !Number.isInteger(secondsNum)
          ) {
            questionErrors.seconds = "Invalid";
          } else {
            // Only compare secondsNum to secTimeNum if minTimeNum is "00" or seconds validation is independent of minTimeNum
            if (secTimeNum !== 0 && minTimeNum === 0) {
              if (secondsNum > secTimeNum) {
                questionErrors.seconds = `The entered timestamp exceeds the video's duration. Please enter a valid timestamp`;
              }
            }
            // If you have additional conditions or logic for when secondsNum should not be compared to secTimeNum,
            // include them in the above conditional statement.
          }
        }
        if (questionIndex > 0) {
          // Ensure there is a previous question to compare with
          const prevQuestion = course.questions[questionIndex - 1];
          const prevMinutes = Number(prevQuestion.minutes);
          const prevSeconds = Number(prevQuestion.seconds);
          const currentMinutes = Number(question.minutes);
          const currentSeconds = Number(question.seconds);

          // Comparing current question's time with the previous question's time
          if (
            currentMinutes === prevMinutes &&
            currentSeconds === prevSeconds
          ) {
            questionErrors.minutes =
              "Current question's time cannot be the same as the previous question's time.";
          }
        }

        // Validate inner items (questionValues)
        question?.questionValues?.forEach((value, valueIndex) => {
          if (!value.title) {
            if (!questionErrors.questionValues) {
              questionErrors.questionValues = [];
            }
            questionErrors.questionValues[valueIndex] = {
              title: `Field cannot be left blank`,
            };
          }
        });

        if (Object.keys(questionErrors).length > 0) {
          if (!courseErrors.questions) {
            courseErrors.questions = [];
          }
          courseErrors.questions[questionIndex] = questionErrors;
        }
      });
    }
    if (course.upload_questions) {
      course?.questions?.forEach((question, questionIndex) => {
        let questionErrors = {};

        // Validate questionTitle
        if (!question.questionTitle) {
          questionErrors.questionTitle = `Field cannot be left blank`;
        }
        if (minTimeNum !== 0) {
          // Proceed with validation if minTime is not "00"
          if (!question.minutes) {
            questionErrors.minutes = "Required";
          } else {
            const minutesNum = Number(question.minutes);
            if (
              isNaN(minutesNum) ||
              minutesNum < 0 ||
              minutesNum > 59 ||
              !Number.isInteger(minutesNum)
            ) {
              questionErrors.minutes = "Invalid";
            } else if (minutesNum > minTimeNum) {
              questionErrors.minutes = "Cannot be greater than episode minTime";
            }
          }
        }

        // Validate seconds
        if (!question.seconds) {
          questionErrors.seconds = "Required";
        } else {
          const secondsNum = Number(question.seconds);
          if (
            isNaN(secondsNum) ||
            secondsNum < 0 ||
            secondsNum > 59 ||
            !Number.isInteger(secondsNum)
          ) {
            questionErrors.seconds = "Invalid";
          } else {
            // Only compare secondsNum to secTimeNum if minTimeNum is "00" or seconds validation is independent of minTimeNum
            if (secTimeNum !== 0 && minTimeNum === 0) {
              if (secondsNum > secTimeNum) {
                questionErrors.seconds =
                  "Cannot be greater than episode secTime";
              }
            }
            // If you have additional conditions or logic for when secondsNum should not be compared to secTimeNum,
            // include them in the above conditional statement.
          }
        }
        if (questionIndex > 0) {
          // Ensure there is a previous question to compare with
          const prevQuestion = course.questions[questionIndex - 1];
          const prevMinutes = Number(prevQuestion.minutes);
          const prevSeconds = Number(prevQuestion.seconds);
          const currentMinutes = Number(question.minutes);
          const currentSeconds = Number(question.seconds);

          // Comparing current question's time with the previous question's time
          if (
            currentMinutes === prevMinutes &&
            currentSeconds === prevSeconds
          ) {
            questionErrors.minutes =
              "Current question's time cannot be the same as the previous question's time.";
          }
        }
        // Validate inner items (questionValues)
        question?.questionValues?.forEach((value, valueIndex) => {
          if (!value.title) {
            if (!questionErrors.questionValues) {
              questionErrors.questionValues = [];
            }
            questionErrors.questionValues[valueIndex] = {
              title: `Field cannot be left blank`,
            };
          }
        });

        if (Object.keys(questionErrors).length > 0) {
          if (!courseErrors.questions) {
            courseErrors.questions = [];
          }
          courseErrors.questions[questionIndex] = questionErrors;
        }
      });
    }
    if (Object.keys(courseErrors).length > 0) {
      if (!errors.formValue) {
        errors.formValue = [];
      }
      errors.formValue[courseIndex] = courseErrors;
    }
  });
  console.log(errors, "errors interactiveQuizzLangValidator");
  return errors;
};
export const feedbackValidator = (values) => {
  let errors = {};
  if (!values.categories) {
    errors.categories = feildCannot;
  }
  if (!values.formName) {
    errors.formName = feildCannot;
  }
  if (values.formName?.length > 50) {
    errors.formName = invalidText;
  }
  if (!values.languageId) {
    errors.languageId = feildCannot;
  }
  if (values.description?.length > 500) {
    errors.description = "Character limit exceeded. Limit: 500 characters";
  }
  values.questions.forEach((question, questionIndex) => {
    let questionErrors = {};
    // Validate episode name
    if (!question.questionTitle) {
      questionErrors.questionTitle = `Field cannot be left blank`;
    }
    if (!question.questionType) {
      questionErrors.questionType = feildCannot;
    }
    if (
      question.questionType === "single_choice" ||
      question.questionType === "rating" ||
      question.questionType === "multiple_choice"
    ) {
      let questionValuesErrors = [];
      question.questionValues.forEach((option, optionIndex) => {
        let optionErrors = {};
        if (!option.title) {
          optionErrors.title = `Field cannot be left blank`;
        }
        if (option.title?.length > 500) {
          optionErrors.title =
            "Character limit exceeded. Limit: 500 characters";
        }
        if (Object.keys(optionErrors).length > 0) {
          questionValuesErrors[optionIndex] = optionErrors;
        }
      });
      if (questionValuesErrors.length > 0) {
        questionErrors.questionValues = questionValuesErrors;
      }
    }

    // If there are any errors for the course, add them to the errors object.
    if (Object.keys(questionErrors).length > 0) {
      errors.questions = errors.questions || [];
      errors.questions[questionIndex] = questionErrors;
    }
  });
  return errors;
};
function validateSpoc(spoc, index, allSpocs) {
  let spocError = {};
  if (!spoc.firstName) {
    spocError.firstName = "This field cannot be empty";
  }
  if (!spoc.lastName) {
    spocError.lastName = "This field cannot be empty";
  }
  if (!spoc.emailId) {
    spocError.emailId = "This field cannot be empty";
  } else if (checkEmail(spoc.emailId)) {
    spocError.emailId = "Invalid Email";
  }
  if (!spoc.designation) {
    spocError.designation = "This field cannot be empty";
  }
  if (!spoc.mobileNumber) {
    spocError.mobileNumber = "This field cannot be empty";
  } else if (spoc.mobileNumber.toString().length > 10) {
    spocError.mobileNumber = "Invalid Mobile Number";
  } else if (spoc.mobileNumber?.length < 10) {
    spocError.mobileNumber = "Invalid Mobile Number";
  }
  if (!spoc.location) {
    spocError.location = "This field cannot be empty";
  }
  const duplicates = allSpocs?.filter(
    (s, idx) => s.firstName === spoc.firstName && idx !== index
  );
  if (duplicates.length > 0) {
    spocError.firstName = "Duplicate first name";
  }
  return spocError;
}
export const clientValidator = (values) => {
  console.log(values, "clientValidator");
  let errors = {};
  if (!values.clientInformation?.clientInformationDetail?.parentEntity) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.parentEntity = feildCannot;
  }
  if (!values.clientInformation?.clientInformationDetail?.legalEntityName) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.legalEntityName =
      feildCannot;
  }
  if (!values.clientInformation?.clientInformationDetail?.organizationName) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.organizationName =
      feildCannot;
  }
  if (!values.clientInformation?.clientInformationDetail?.industry) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.industry = feildCannot;
  }
  if (
    !values.clientInformation?.clientInformationDetail?.companyAddress
      ?.addressLineOne
  ) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.companyAddress =
      errors.clientInformation.clientInformationDetail.companyAddress || {};
    errors.clientInformation.clientInformationDetail.companyAddress.addressLineOne =
      feildCannot;
  }
  if (
    !values.clientInformation?.clientInformationDetail?.companyAddress
      ?.addressLineTwo
  ) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.companyAddress =
      errors.clientInformation.clientInformationDetail.companyAddress || {};
    errors.clientInformation.clientInformationDetail.companyAddress.addressLineTwo =
      feildCannot;
  }
  if (
    !values.clientInformation?.clientInformationDetail?.companyAddress?.country
  ) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.companyAddress =
      errors.clientInformation.clientInformationDetail.companyAddress || {};
    errors.clientInformation.clientInformationDetail.companyAddress.country =
      feildCannot;
  }
  if (
    !values.clientInformation?.clientInformationDetail?.companyAddress?.pinCode
  ) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.companyAddress =
      errors.clientInformation.clientInformationDetail.companyAddress || {};
    errors.clientInformation.clientInformationDetail.companyAddress.pinCode =
      feildCannot;
  }
  if (
    values.clientInformation?.clientInformationDetail?.companyAddress?.pinCode
      ?.length > 6
  ) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.companyAddress =
      errors.clientInformation.clientInformationDetail.companyAddress || {};
    errors.clientInformation.clientInformationDetail.companyAddress.pinCode =
      "Invalid Pin Code";
  }
  if (
    !values.clientInformation?.clientInformationDetail?.companyAddress?.city
  ) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.companyAddress =
      errors.clientInformation.clientInformationDetail.companyAddress || {};
    errors.clientInformation.clientInformationDetail.companyAddress.city =
      feildCannot;
  }
  if (
    !values.clientInformation?.clientInformationDetail?.companyAddress?.state
  ) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientInformationDetail =
      errors.clientInformation.clientInformationDetail || {};
    errors.clientInformation.clientInformationDetail.companyAddress =
      errors.clientInformation.clientInformationDetail.companyAddress || {};
    errors.clientInformation.clientInformationDetail.companyAddress.state =
      feildCannot;
  }
  if (!values.clientInformation?.uploadLogo?.logoImage) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.uploadLogo =
      errors.clientInformation.uploadLogo || {};
    errors.clientInformation.uploadLogo.logoImage = feildCannot;
  }
  if (!values.clientInformation?.clientSigatoryUrl) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.clientSigatoryUrl =
      errors.clientInformation.clientSigatoryUrl || {};
    errors.clientInformation.clientSigatoryUrl = feildCannot;
  }
  if (!values.clientInformation?.RmSalesSpoc) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.RmSalesSpoc = feildCannot;
  }
  if (!values.clientInformation?.RmOperationSpoc) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.RmOperationSpoc = feildCannot;
  }
  // if (
  //   values.clientInformation.ClientSpoc &&
  //   values.clientInformation.ClientSpoc?.length > 0
  // ) {
  //   let spocErrors = values.clientInformation.ClientSpoc?.map((spoc) => {
  //     let spocError = {};

  //     // Validate name
  //     if (!spoc.firstName) {
  //       spocError.firstName = feildCannot;
  //     }
  //     if (!spoc.lastName) {
  //       spocError.lastName = feildCannot;
  //     }
  //     if (!spoc.emailId) {
  //       spocError.emailId = "Enter Email-Id";
  //     } else if (checkEmail(spoc.emailId)) {
  //       spocError.emailId = "Invalid Email";
  //     }
  //     if (!spoc.designation) {
  //       spocError.designation = "Enter Designation";
  //     }
  //     if (!spoc.mobileNumber) {
  //       spocError.mobileNumber = "Enter Mobile Number";
  //     }
  //     if (spoc.mobileNumber?.toString()?.length > 10) {
  //       spocError.mobileNumber = "Invalid Mobile Number";
  //     }
  //     if (!spoc.location) {
  //       spocError.location = "Enter Location";
  //     }

  //     return spocError;
  //   });

  //   spocErrors = spocErrors.filter((error) => Object.keys(error).length > 0);

  //   if (spocErrors?.length > 0) {
  //     if (!errors.clientInformation) errors.clientInformation = {};
  //     errors.clientInformation.ClientSpoc = spocErrors;
  //   }
  // }
  // if (
  //   values.clientInformation.ClientSpoc &&
  //   values.clientInformation.ClientSpoc.length > 0
  // ) {
  //   const spocErrors = values.clientInformation.ClientSpoc?.map(
  //     (spoc, index, allSpocs) => validateSpoc(spoc, index, allSpocs)
  //   );
  //   if (spocErrors?.some((error) => Object?.keys(error).length > 0)) {
  //     errors.clientInformation = {
  //       ...errors.clientInformation,
  //       ClientSpoc: spocErrors,
  //     };
  //   }
  // }
  // if (
  //   values.clientInformation.ClientAdmin &&
  //   values.clientInformation.ClientAdmin.length > 0
  // ) {
  //   const adminErrors = values.clientInformation.ClientAdmin?.map(
  //     (admin, index, allAdmins) => validateSpoc(admin, index, allAdmins)
  //   );
  //   if (adminErrors?.some((error) => Object?.keys(error).length > 0)) {
  //     errors.clientInformation = {
  //       ...errors.clientInformation,
  //       ClientAdmin: adminErrors,
  //     };
  //   }
  // }
  // if (
  //   values.clientInformation.ClientAdmin &&
  //   values.clientInformation.ClientAdmin?.length > 0
  // ) {
  //   let spocErrors = values.clientInformation.ClientAdmin?.map((spoc) => {
  //     let spocError = {};

  //     // Validate name
  //     if (!spoc.firstName) {
  //       spocError.firstName = feildCannot;
  //     }
  //     if (!spoc.lastName) {
  //       spocError.lastName = feildCannot;
  //     }
  //     if (!spoc.emailId) {
  //       spocError.emailId = feildCannot;
  //     } else if (checkEmail(spoc.emailId)) {
  //       spocError.emailId = "Invalid Email";
  //     }
  //     if (!spoc.designation) {
  //       spocError.designation = feildCannot;
  //     }
  //     if (!spoc.mobileNumber) {
  //       spocError.mobileNumber = feildCannot;
  //     } else if (spoc.mobileNumber?.toString()?.length > 10) {
  //       spocError.mobileNumber = "Invalid Mobile Number";
  //     }
  //     if (!spoc.location) {
  //       spocError.location = feildCannot;
  //     }

  //     return spocError;
  //   });

  //   spocErrors = spocErrors.filter((error) => Object.keys(error).length > 0);

  //   if (spocErrors.length > 0) {
  //     if (!errors.clientInformation) errors.clientInformation = {};
  //     errors.clientInformation.ClientAdmin = spocErrors;
  //   }
  // }
  if (values.clientInformation?.groupIds?.length === 0) {
    errors.clientInformation = errors.clientInformation || {};
    errors.clientInformation.groupIds = feildCannot;
  }
  console.log(errors, "errors");
  return errors;
};
export const ClientBillingValidator = (values) => {
  let errors = {};
  if (!values.billingInformation?.billingName) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.billingName = feildCannot;
  }
  if (!values.billingInformation?.billingContact) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.billingContact = feildCannot;
  }
  // if (values.billingInformation?.billingContact?.toString()?.length > 10) {
  //   errors.billingInformation = errors.billingInformation || {};
  //   errors.billingInformation.billingContact = "Invalid Billing Contact";
  // }
  // if (values.billingInformation?.billingContact?.length > 10) {
  //   errors.billingInformation = errors.billingInformation || {};
  //   errors.billingInformation.billingContact = "Invalid Billing Contact";
  // }
  if (!values.billingInformation?.billingAddress1) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.billingAddress1 = feildCannot;
  }
  if (!values.billingInformation?.billingAddress2) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.billingAddress2 = feildCannot;
  }
  if (!values.billingInformation?.country) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.country = feildCannot;
  }
  if (!values.billingInformation?.pincode) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.pincode = feildCannot;
  }
  if (!values.billingInformation?.city) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.city = feildCannot;
  }
  if (!values.billingInformation?.state) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.state = feildCannot;
  }
  if (!values.billingInformation?.gst_number) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.gst_number = feildCannot;
  } else if (!regex?.test(values.billingInformation?.gst_number)) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.gst_number = "Invali GST Number";
  }
  if (!values.billingInformation?.currency) {
    errors.billingInformation = errors.billingInformation || {};
    errors.billingInformation.currency = feildCannot;
  }
  return errors;
};
export const URLValidator = (values) => {
  let errors = {};
  if (!values.urls) {
    errors.urls = feildCannot;
  }
  return errors;
};
export const clientCourseValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.categories) {
    errors.categories = feildCannot;
  }
  if (!values.courseId) {
    errors.courseId = feildCannot;
  }
  if (!values.startDate) {
    errors.startDate = feildCannot;
  }
  if (!values.endDate) {
    errors.endDate = feildCannot;
  }
  if (!values.numberOfLicensesPurchased) {
    errors.numberOfLicensesPurchased = feildCannot;
  }
  if (!values.costPerLicences) {
    errors.costPerLicences = feildCannot;
  }
  if (!values.licencesCostInfiation) {
    errors.licencesCostInfiation = feildCannot;
  } else if (values.licencesCostInfiation > 99) {
    errors.licencesCostInfiation = "Invalid";
  }
  if (values.batchPurchase) {
    if (!values.batchSize) {
      errors.batchSize = feildCannot;
    }
    if (!values.cost_per_license2) {
      errors.cost_per_license2 = feildCannot;
    }
  }
  if (!values.courseActivationDate) {
    errors.courseActivationDate = feildCannot;
  }
  if (!values.courseTime) {
    errors.courseTime = feildCannot;
  }
  if (values.languages?.length === 0) {
    errors.languages = feildCannot;
  }
  return errors;
};
export const industryValidator = (values) => {
  let errors = {};
  let namesSeen = new Set();
  if (values?.isUpload) {
    if (!values.fileName) {
      errors.fileName = feildCannot;
    }
  }
  values.data?.forEach((item, index) => {
    let itemErrors = {};

    if (!item.title) {
      itemErrors.title = "Field cannot be left blank.";
    } else {
      if (item.title.length > 50) {
        itemErrors.title = "Name is too long.";
      }
      // Check for duplicate names
      if (namesSeen.has(item.title)) {
        itemErrors.title = "Duplicate title found.";
      } else {
        namesSeen.add(item.title);
      }
    }
    if (Object.keys(itemErrors).length > 0) {
      if (!errors.data) {
        errors.data = [];
      }
      errors.data[index] = itemErrors;
    }
  });
  console.log(errors, "learner val");
  return errors;
};
export const bulkDesigValidator = (values) => {
  let errors = {};
  let namesSeen = new Set();

  console.log(values, "bulkDesigValidator");

  values.data?.forEach((item, index) => {
    let itemErrors = {};

    if (!item.title) {
      itemErrors.title = "Field cannot be left blank.";
    } else {
      if (item.title.length > 50) {
        itemErrors.title = "Name is too long.";
      }
      // Check for duplicate names
      if (namesSeen.has(item.title)) {
        itemErrors.title = "Duplicate title found.";
      } else {
        namesSeen.add(item.title);
      }
    }
    // if (!item.departmentId) {
    //   itemErrors.departmentId = feildCannot;
    // }
    if (Object.keys(itemErrors).length > 0) {
      if (!errors.data) {
        errors.data = [];
      }
      errors.data[index] = itemErrors;
    }
  });
  console.log(errors, "learner val");
  return errors;
};
export const learnerClientValidator = (values) => {
  let errors = {};
  let namesSeen = new Set();

  console.log(values, "values");
  if (values?.isUpload) {
    if (!values.fileUrl) {
      errors.fileUrl = feildCannot;
    }
  }
  if (values.isAdd) {
    values.data?.forEach((item, index) => {
      let itemErrors = {};

      // Validation logic for each item
      if (!item.name) {
        itemErrors.name = "Field cannot be left blank.";
      } else {
        if (item.name.length > 50) {
          itemErrors.name = "Name is too long.";
        }
        // Check for duplicate names
        if (namesSeen.has(item.name)) {
          itemErrors.name = "Duplicate name found.";
        } else {
          namesSeen.add(item.name); // Add name to the set if not a duplicate
        }
      }
      if (!item.email) {
        itemErrors.email = feildCannot;
      } else if (checkEmail(item.email)) {
        itemErrors.email = "Invalid email format.";
      }
      // if (!item.department) {
      //   itemErrors.department = feildCannot;
      // }
      // if (!item.reportingManager) {
      //   itemErrors.reportingManager = feildCannot;
      // }
      // if (!item.city) {
      //   itemErrors.city = feildCannot;
      // }
      // if (!item.designation) {
      //   itemErrors.designation = feildCannot;
      // }
      // if (!item.subEntity) {
      //   itemErrors.subEntity = feildCannot;
      // }
      if (Object.keys(itemErrors).length > 0) {
        if (!errors.data) {
          errors.data = [];
        }
        errors.data[index] = itemErrors;
      }
    });
  }
  console.log(errors, "learner val");
  return errors;
};
export const comiteClientValidator = (values) => {
  let errors = {};
  if (!values.committeeName) {
    errors.committeeName = feildCannot;
  } else if (values.committeeName?.length > 50) {
    errors.committeeName = invalidText;
  }
  if (!values.city) {
    errors.city = feildCannot;
  } else if (values.city?.length > 50) {
    errors.city = invalidText;
  }
  return errors;
};
export const memberValidator = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = feildCannot;
  } else if (values.name?.length > 50) {
    errors.name = invalidText;
  }
  if (!values.email) {
    errors.email = feildCannot;
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  // if (!values.mobileNumber) {
  //   errors.mobileNumber = feildCannot;
  // }
  // if (values.mobileNumber?.length > 10) {
  //   errors.mobileNumber = "Invalid Mobile Number";
  // }
  // if (!values.city) {
  //   errors.city = feildCannot;
  // }
  // if (!values.designation) {
  //   errors.designation = feildCannot;
  // }
  console.log(errors, "errors errors");
  return errors;
};
export const policyClientValidator = (values) => {
  console.log(values, "policyClientValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = "Character limit exceeded. Limit: 50 characters";
  }
  if (values.documents?.length === 0) {
    errors.documents = feildCannot;
  }

  return errors;
};

export const groupClientValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values?.title.length > 50) {
    errors.title = "Character limit exceeded. Limit: 50 characters";
  }
  if (values?.description.length > 500) {
    errors.description = "Character limit exceeded. Limit: 500 characters";
  }
  // if (values.categoriesIds?.length === 0) {
  //   errors.categoriesIds = feildCannot;
  // }
  // if (values.courseIds?.length === 0) {
  //   errors.courseIds = feildCannot;
  // }
  // if (values.industry?.length === 0) {
  //   errors.industry = feildCannot;
  // }
  // if (values.city?.length === 0) {
  //   errors.city = feildCannot;
  // }
  if (values.clientIds?.length === 0) {
    errors.clientIds = feildCannot;
  }
  return errors;
};
export const courseDeleteValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (values.courseIds?.length === 0) {
    errors.courseIds = feildCannot;
  }
  if (values.categorieIds?.length === 0) {
    errors.categorieIds = feildCannot;
  }
  return errors;
};
export const episodeDeleteValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (values.courseIds?.length === 0) {
    errors.courseIds = feildCannot;
  }
  if (values.categorieIds?.length === 0) {
    errors.categorieIds = feildCannot;
  }
  if (values.episodeIds?.length === 0) {
    errors.episodeIds = feildCannot;
  }
  if (values.languageIds?.length === 0) {
    errors.languageIds = feildCannot;
  }
  return errors;
};
export const learnerDeleteValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (values.clientIds?.length === 0) {
    errors.clientIds = feildCannot;
  }
  if (values.courseIds?.length === 0) {
    errors.courseIds = feildCannot;
  }
  if (values.categorieIds?.length === 0) {
    errors.categorieIds = feildCannot;
  }
  return errors;
};
export const departValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  return errors;
};
export const profileValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.firstName) {
    errors.firstName = feildCannot;
  }
  if (values.firstName?.length > 50) {
    errors.firstName = invalidText;
  }
  if (!values.lastName) {
    errors.lastName = feildCannot;
  }
  if (values.lastName?.length > 50) {
    errors.lastName = invalidText;
  }
  return errors;
};
export const passwordValidator = (values) => {
  let errors = {};
  if (!values.oldPassword) {
    errors.oldPassword = feildCannot;
  }
  if (!values.newPassword) {
    errors.newPassword = feildCannot;
  } else if (values.newPassword.length < 8) {
    errors.newPassword = "Invalid Password";
  } else if (!passwordRegex.test(values.newPassword)) {
    errors.newPassword = "Invalid Password";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = feildCannot;
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Password does not match";
  }
  return errors;
};
export const surveyGroupValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (values.description?.length > 500) {
    errors.description = "Character limit exceeded. Limit: 500 characters";
  }
  return errors;
};
export const emailSendValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.from) {
    errors.from = feildCannot;
  }
  if (values.sendTo?.length === 0) {
    errors.sendTo = feildCannot;
  }
  if (!values.subject) {
    errors.subject = feildCannot;
  }
  if (!values.body) {
    errors.body = feildCannot;
  }
  return errors;
};
export const designationValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  // if (!values.departmentId) {
  //   errors.departmentId = feildCannot;
  // }
  return errors;
};
export const surveyValidator = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.surveyGroup) {
    errors.surveyGroup = feildCannot;
  }
  // if (!values.description) {
  //   errors.description = feildCannot;
  // }
  if (values.description?.length > 500) {
    errors.description = "Character limit exceeded. Limit: 500 characters";
  }
  values.questions.forEach((question, questionIndex) => {
    let questionErrors = {};
    // Validate episode name
    if (!question.question) {
      questionErrors.question = `Field cannot be left blank`;
    }
    if (!question.questionType) {
      questionErrors.questionType = feildCannot;
    }
    if (
      question.questionType === "single_choice" ||
      question.questionType === "description" ||
      question.questionType === "rating" ||
      question.questionType === "multiple_choice"
    ) {
      let questionValuesErrors = [];
      question.answerOptions.forEach((option, optionIndex) => {
        let optionErrors = {};
        if (!option.title) {
          optionErrors.title = `Field cannot be left blank`;
        }
        if (option.title?.length > 500) {
          optionErrors.title =
            "Character limit exceeded. Limit: 500 characters";
        }
        if (Object.keys(optionErrors).length > 0) {
          questionValuesErrors[optionIndex] = optionErrors;
        }
      });
      if (questionValuesErrors.length > 0) {
        questionErrors.answerOptions = questionValuesErrors;
      }
    }

    // If there are any errors for the course, add them to the errors object.
    if (Object.keys(questionErrors).length > 0) {
      errors.questions = errors.questions || [];
      errors.questions[questionIndex] = questionErrors;
    }
  });
  return errors;
};
export const trailerValidatore = (values) => {
  const errors = {};
  const fieldCannot = "This field cannot be empty";

  // Validate basic fields
  if (!values.translateCategory) errors.translateCategory = fieldCannot;
  if (!values.translateCourseName) errors.translateCourseName = fieldCannot;
  if (!values.baiseDescription) errors.baiseDescription = fieldCannot;
  if (values.baiseDescription?.length > 2000)
    errors.baiseDescription =
      "Character limit exceeded. Limit: 2000 characters";
  if (!values.logoImage) errors.logoImage = fieldCannot;
  if (!values.bannerTrailerImageNameUrl)
    errors.bannerTrailerImageNameUrl = fieldCannot;
  if (!values.thumbnailImage) errors.thumbnailImage = fieldCannot;

  // Validate trailers
  if (values.trailers && values.trailers?.length > 0) {
    let trailersHaveErrors = false;
    const seenTitles = new Set();

    const trailerErrors = values.trailers?.map((trailer) => {
      const trailerError = {};
      // Assuming 'title' is required for each trailer
      if (!trailer.title) {
        trailersHaveErrors = true;
        trailerError.title = "Title is required";
      } else {
        // Check for duplicate titles
        if (seenTitles.has(trailer.title)) {
          trailersHaveErrors = true;
          trailerError.title = "Duplicate title found";
        } else {
          seenTitles.add(trailer.title);
        }

        // Check for title length
        if (trailer.title?.length > 50) {
          trailersHaveErrors = true;
          trailerError.title = "Character limit exceeded. Limit: 50 characters";
        }
      }
      if (trailer.description && trailer.description?.length > 200) {
        trailersHaveErrors = true;
        trailerError.description =
          "Character limit exceeded. Limit: 200 characters";
      }

      return trailerError;
    });

    // Only add trailer errors if any were found
    if (trailersHaveErrors) {
      errors.trailers = trailerErrors;
    }
  } else {
    // Set an error if no trailers are provided
    errors.trailers = ["At least one trailer must be provided"];
  }

  // if (values.trailers && values.trailers.length > 0) {
  //   let trailerErrors = values.trailers.map((trailer) => {
  //     const trailerError = {};
  //     if (!trailer.title) {
  //       trailerError.title = "This field cannot be empty"; // Custom message per trailer
  //     }
  //     return trailerError;
  //   });

  //   // Filter out any empty error objects to avoid React rendering issues
  //   trailerErrors = trailerErrors.filter(
  //     (error) => Object.keys(error).length > 0
  //   );

  //   if (trailerErrors.length > 0) {
  //     errors.trailers = trailerErrors;
  //   }
  // } else {
  //   // Set an error if no trailers are provided
  //   errors.trailers = ["At least one trailer must be provided"]; // Ensure this is an array with strings
  // }

  console.log(errors, "errors  errors errorserrors");
  return errors;
};

export const courseEpisodeValidatore = (values) => {
  console.log(values, "courseEpisodeValidatore");
  let errors = {};
  if (!values?.courseEpisodeeName) {
    errors.courseEpisodeeName = feildCannot;
  }
  if (values?.courseEpisodeeName?.length > 50) {
    errors.courseEpisodeeName = invalidText;
  }
  if (values?.courseTags?.length === 0) {
    errors.courseTags = feildCannot;
  }
  if (values?.courseDescription?.length > 2000) {
    errors.courseDescription =
      "Character limit exceeded. Limit: 2000 characters";
  }
  if (values?.courseEpisodes?.length === 0) {
    errors.courseEpisodes = feildCannot;
  }
  if (!values?.url) {
    errors.url = feildCannot;
  }

  const minutesNum = Number(values.minutes);
  const secondsNum = Number(values.seconds);
  const minutesNumMusic = Number(values.minutesMusic);
  const secondsNumMusic = Number(values.secondsMusic);
  const minTimeNum = Number(values.minTime);
  const secTimeNum = Number(values.secTime);

  if (values.placementType === 2) {
    if (!values.seconds) {
      errors.seconds = "Required";
    }
    if (minutesNum > minTimeNum) {
      errors.minutes = `Minutes should not exceed ${minTimeNum}`;
    } else if (minutesNum === minTimeNum && secondsNum > secTimeNum) {
      errors.seconds = `Seconds should not exceed ${secTimeNum} when minutes are set to the maximum allowed`;
    }

    const duplicateTime = values.combineVideoMusic?.some((video) => {
      return (
        Number(video.minutes) === minutesNum &&
        Number(video.seconds) === secondsNum
      );
    });

    if (duplicateTime) {
      errors.seconds = "This time is already scheduled in another File";
    }
  }
  if (values.placementTypeMusic === 2) {
    if (!values.secondsMusic) {
      errors.secondsMusic = "Required";
    }
    if (minutesNumMusic > minTimeNum) {
      errors.minutesMusic = `Minutes should not exceed ${minTimeNum}`;
    } else if (minutesNumMusic === minTimeNum && secondsNumMusic > secTimeNum) {
      errors.secondsMusic = `Seconds should not exceed ${secTimeNum} when minutes are set to the maximum allowed`;
    }

    const duplicateTime = values.combineVideoMusic?.some((video) => {
      return (
        Number(video.minutes) === minutesNumMusic &&
        Number(video.seconds) === secondsNumMusic
      );
    });

    if (duplicateTime) {
      errors.secondsMusic = "This time is already scheduled in another File";
    }
  }
  console.log(errors, "APpend Video Validatore");
  return errors;
};

// export const clientQuizzInterLangValidator = (values) => {
//   let errors = {};

//   if (values.questions?.length > 0) {
//     let questionsErrors = [];

//     values.questions.forEach((question, questionIndex) => {
//       let questionErrors = {};
//       const minTimeNum = Number(question.minTime);
//       const secTimeNum = Number(question.secTime);

//       if (!question.question) {
//         questionErrors.question = "Field cannot be left blank.";
//       }

//       const minutesNum = Number(question.minutes);
//       if (!question.minutes) {
//         questionErrors.minutes = "Minutes are required";
//       } else if (
//         isNaN(minutesNum) || minutesNum < 0 || minutesNum > 59 || !Number.isInteger(minutesNum)
//       ) {
//         questionErrors.minutes = "Invalid minutes value";
//       } else if (minutesNum > minTimeNum) {
//         questionErrors.minutes = `Minutes should not exceed ${minTimeNum}`;
//       }

//       const secondsNum = Number(question.seconds);
//       if (!question.seconds) {
//         questionErrors.seconds = "Seconds are required";
//       } else if (
//         isNaN(secondsNum) || secondsNum < 0 || secondsNum > 59 || !Number.isInteger(secondsNum)
//       ) {
//         questionErrors.seconds = "Invalid seconds value";
//       } else if (minutesNum === minTimeNum && secondsNum > secTimeNum) {
//         questionErrors.seconds = `Seconds should not exceed ${secTimeNum} when minutes are set to the maximum allowed`;
//       }

//       if (questionIndex > 0) {
//         const prevQuestion = values.questions[questionIndex - 1];
//         if (
//           Number(prevQuestion.minutes) === minutesNum &&
//           Number(prevQuestion.seconds) === secondsNum
//         ) {
//           questionErrors.minutes = "Current question's time cannot be the same as the previous question's time.";
//         }
//       }

//       if (Object.keys(questionErrors).length > 0) {
//         questionsErrors[questionIndex] = questionErrors;
//       }
//     });

//     if (questionsErrors.length > 0) {
//       errors.questions = questionsErrors;
//     }
//   }

//   console.log(errors, "errors")
//   return errors;
// };

export const clientQuizzInterLangValidator = (values) => {
  console.log(values, "Client interactive");
  let errors = {};

  if (values.questions?.length > 0) {
    let questionsErrors = [];

    const minTimeNum = Number(values.minTime);
    const secTimeNum = Number(values.secTime);
    values.questions?.forEach((question, questionIndex) => {
      let questionErrors = {};

      // Question text validation
      if (!question.question) {
        questionErrors.question = "Field cannot be left blank.";
      }

      const minutesNum = Number(question.minutes);
      const secondsNum = Number(question.seconds);

      console.log(
        { minutesNum, minTimeNum, secondsNum, secTimeNum },
        " same tame "
      );

      // Minutes validation only if minTime is greater than 0
      if (minTimeNum > 0) {
        if (!question.minutes) {
          questionErrors.minutes = "Minutes are required";
        } else if (
          isNaN(minutesNum) ||
          minutesNum < 0 ||
          minutesNum > 59 ||
          !Number.isInteger(minutesNum)
        ) {
          questionErrors.minutes = "Invalid minutes value";
        } else if (minutesNum > minTimeNum) {
          questionErrors.minutes = `Minutes should not exceed ${minTimeNum}`;
        }
      }

      // Seconds validation
      if (!question.seconds) {
        questionErrors.seconds = "Seconds are required";
      } else if (
        isNaN(secondsNum) ||
        secondsNum < 0 ||
        secondsNum > 59 ||
        !Number.isInteger(secondsNum)
      ) {
        questionErrors.seconds = "Invalid seconds value";
      } else if (minutesNum === minTimeNum && secondsNum > secTimeNum) {
        questionErrors.seconds = `Seconds should not exceed ${secTimeNum} when minutes are set to the maximum allowed`;
      }

      // Checking for duplicate times
      if (questionIndex > 0) {
        const prevQuestion = values.questions[questionIndex - 1];
        if (
          Number(prevQuestion.minutes) === minutesNum &&
          Number(prevQuestion.seconds) === secondsNum
        ) {
          questionErrors.minutes =
            "Current question's time cannot be the same as the previous question's time.";
        }
      }

      // Collecting errors
      if (Object.keys(questionErrors).length > 0) {
        questionsErrors[questionIndex] = questionErrors;
      }
    });

    // Setting errors for questions
    if (questionsErrors.length > 0) {
      errors.questions = questionsErrors;
    }
  }

  console.log(errors); // For debugging purposes
  return errors;
};
export const SpecialCertificatessValidator = (values) => {
  // console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.certificateTitle) {
    errors.certificateTitle = feildCannot;
  }
  if (values.certificateTitle?.length > 50) {
    errors.certificateTitle = invalidText;
  }
  if (!values.clientName) {
    errors.clientName = feildCannot;
  }
  if (values.clientName?.length > 50) {
    errors.clientName = invalidText;
  }
  if (!values.companyName) {
    errors.companyName = feildCannot;
  }
  if (values.companyName?.length > 50) {
    errors.companyName = invalidText;
  }
  if (!values.templateId) {
    errors.templateId = feildCannot;
  }
  if (!values.certificateText) {
    errors.certificateText = feildCannot;
  }
  if (values.isMannual) {
    if (!values.issueDate) {
      errors.issueDate = feildCannot;
    }
  }
  if (values.clientLogoStatus) {
    if (!values.clientLogoUrl) {
      errors.clientLogoUrl = feildCannot;
    }
  }
  if (values.clientSigatoryStatus) {
    if (!values.clientSigatoryUrl) {
      errors.clientSigatoryUrl = feildCannot;
    }
  }
  if (values.status === "sendEmail") {
    if (!values.subject) {
      errors.subject = feildCannot;
    }
    if (values.sendTo?.length === 0) {
      errors.sendTo = feildCannot;
    }
    if (!values.body) {
      errors.body = feildCannot;
    }
  }
  if (values.isUploadLearner) {
    if (!values.fileUrl) {
      errors.fileUrl = feildCannot;
    }
  }

  console.log(errors);
  return errors;
};
export const ClientAdminSPOCValidator = (values) => {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = feildCannot;
  }
  if (values.firstName?.length > 50) {
    errors.firstName = invalidText;
  }
  if (!values.lastName) {
    errors.lastName = feildCannot;
  }
  if (values.lastName?.length > 50) {
    errors.lastName = invalidText;
  }
  if (!values.email) {
    errors.email = feildCannot;
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }

  return errors;
};
export const companyDetailsValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.organization_name) {
    errors.organization_name = feildCannot;
  }
  if (values.organization_name?.length > 50) {
    errors.organization_name = invalidText;
  }
  if (!values.GST_number) {
    errors.GST_number = feildCannot;
  } else if (!regex?.test(values.GST_number)) {
    errors.GST_number = "Invali GST Number";
  }
  return errors;
};
export const templateValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = invalidText;
  }
  if (!values.body) {
    errors.body = feildCannot;
  }
  return errors;
};
