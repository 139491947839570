import CryptoJS from "crypto-js";
import { Country, State, City } from "country-state-city";
import { useSelector } from "react-redux";
const secretKeyMain = "rmAdminSecretKey";
export const decryptData = (encryptedData) => {
  if (!encryptedData) {
    return null; // Return null if no encrypted data is available
  }

  try {
    // Decrypt data using AES and secret key
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKeyMain);
    const decryptedData = bytes?.toString(CryptoJS.enc.Utf8);

    // If the result is empty, return null to prevent JSON parsing errors
    if (!decryptedData) {
      return null;
    }

    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption error:", error);
    return null; // Return null if there's a decryption error
  }
};

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${formattedSize} ${sizes[i]}`;
}

const secretKey = "yourSecretKey";
export const encryptedData = (dataToEncrypt) => {
  return CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();
};
export const decryptedData = (dataToEncrypt) => {
  var bytes = CryptoJS.AES.decrypt(dataToEncrypt, secretKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export function removeDuplicatesById(arr) {
  const seenIds = new Set();
  return arr.filter((obj) => {
    if (seenIds.has(obj._id)) {
      return false; // Duplicate _id, filter it out
    }
    seenIds.add(obj._id);
    return true; // Unique _id, keep it
  });
}

export function combineDateAndTime(dateInput, timeInput) {
  console.log(timeInput, "timeInput timeInput");

  // Ensure timeInput is a valid string and can be split correctly
  if (timeInput && timeInput?.includes(":")) {
    const [hours, minutes, seconds = 0] = timeInput?.split(":")?.map(Number);

    // Ensure dateInput is a valid date object
    if (dateInput instanceof Date && !isNaN(dateInput)) {
      dateInput.setHours(hours, minutes, seconds, 0);

      const pad = (num) => num?.toString()?.padStart(2, "0");

      const localFormattedDate = `${dateInput?.getFullYear()}-${pad(
        dateInput?.getMonth() + 1
      )}-${pad(dateInput?.getDate())}T${pad(dateInput?.getHours())}:${pad(
        dateInput?.getMinutes()
      )}`;

      console.log(
        localFormattedDate?.split("T")[0] + "T" + timeInput,
        "localFormattedDate localFormattedDate"
      );

      return localFormattedDate?.split("T")[0] + "T" + timeInput;
    } else {
      console.error("Invalid dateInput");
      return null;
    }
  } else {
    console.error("Invalid timeInput");
    return null;
  }
}

export function formatDuration(durationInSeconds) {
  const seconds = Math.floor(durationInSeconds % 60);
  const minutes = Math.floor((durationInSeconds / 60) % 60);
  const hours = Math.floor(durationInSeconds / 3600);

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
export function convertTimeFormatMin(timeStr) {
  let [hh, mm, ss] = timeStr?.split(":");
  return `${parseInt(mm)} Min.`;
}
export const CountryList = () => {
  let list = Country.getAllCountries()?.map((country) => {
    return {
      value: country.isoCode,
      label: country.name,
    };
  });
  return list;
};
export const StateList = (e) => {
  let list = State.getStatesOfCountry(e)?.map((state) => {
    return {
      value: state.isoCode,
      label: state.name,
    };
  });
  return list;
};
export const CityList = (country, state) => {
  let list = City.getCitiesOfState(country, state)?.map((city) => {
    return {
      value: city.name,
      label: city.name,
    };
  });
  return list;
};

export const formatTime = (time) => {
  //formarting duration of video
  if (isNaN(time)) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, "0")} `;
  } else return `${minutes}:${seconds}`;
};

export function checkImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width === 432 && img.height === 132) {
        resolve(true);
      } else {
        reject("Image must be exactly W.432 x H.132 pixels");
      }
    };
    img.onerror = () => {
      reject("Error loading image");
    };
    img.src = URL.createObjectURL(file);
  });
}
export function getWeekOfMonth(date) {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const startDayOfMonth = startOfMonth.getDay();
  return Math.ceil((date.getDate() + startDayOfMonth) / 7);
}
export function checkImageDimensionsClient(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width === 576 && img.height === 189) {
        resolve(true);
      } else {
        reject("Image must be exactly W.576 x H.180 pixels");
      }
    };
    img.onerror = () => {
      reject("Error loading image");
    };
    img.src = URL.createObjectURL(file);
  });
}

export function getRemainingDays(targetDate) {
  // Convert the target date string to a Date object
  const givenDate = new Date(targetDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffInMilliseconds = givenDate - currentDate;

  // Convert milliseconds to days
  const remainingDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  return remainingDays;
}
export const AccessCheck = (key) => {
  const encryptedRainDataToken = localStorage.getItem("rainDataToken");
  let data = decryptData(encryptedRainDataToken);
  const userData = data;
  let moduleExists = userData?.permissions?.find((el) => {
    return (
      key?.includes(el.key) &&
      el.permissions?.some((permission) => {
        return permission.selected;
      })
    );
  });
  return !!moduleExists;
};
export const SubAccessCheck = (key) => {
  const encryptedRainDataToken = localStorage.getItem("rainDataToken");
  let data = decryptData(encryptedRainDataToken);
  const userData = data;
  let moduleExists = userData?.permissions?.find((el) => {
    return (
      el.key === key &&
      el.permissions?.some((permission) => {
        return permission.selected;
      })
    );
  });
  return !!moduleExists;
};
export const AccessCheckAction = (key, permision_key) => {
  const encryptedRainDataToken = localStorage.getItem("rainDataToken");
  let data = decryptData(encryptedRainDataToken);
  const userData = data;
  const superAdmin = data?.isSuperAdmin;
  // console.log(superAdmin);
  if (superAdmin) {
    return true;
  }
  let moduleExists = userData?.permissions?.find((el) => {
    return (
      el.key === key &&
      el.permissions?.some((permission) => {
        return permission.selected && permission.key === permision_key;
      })
    );
  });
  return !!moduleExists;
};

export const permissionTypekey = {
  listData: "view",
  createAndView: "create_and_view",
  viewAndDelete: "view_and_delete",
  viewAndChanges: "view_and_changes",
};

export function cleanJsonString(escapedJsonString) {
  let jsonString = escapedJsonString;
  while (jsonString.indexOf("\\") !== -1) {
    jsonString = jsonString?.replace(/\\'/g, "'");
  }
  return JSON.parse(jsonString);
}
export const dateConvert = (e) => {
  const date = new Date(e);
  const formattedDate = date?.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};
